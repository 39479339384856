import * as React from 'react';
import Layout from '../components/Layout';

const MaintenancePage = () => (
  <Layout>
    <div>
      <h1>Le site est en maintencance, revenez bientôt!!!</h1>
    </div>
  </Layout>
);

export default MaintenancePage;
